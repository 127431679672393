import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { MedicineStripe } from '@/services/global';
import { IProduct } from '@/store/ducks/products/types';

import withoutImage from '../../../assets/images/without-image.png';
import BgImage from '../../global/Image/BgImage';
import MiniImages from '../miniImages/MiniImages';

interface Props {
  fullProduct: IProduct;
}

function ImagePreview({ fullProduct }: Props) {
  const [imageSelected, setImageSelected] = useState<string>('');
  const changeImageSelected = (source: string) => {
    setImageSelected(source);
  };
  const { order, configs } = useWebContext();

  const addHighQualityImage = () => {
    if (fullProduct && fullProduct.high_quality_image) {
      fullProduct.images.unshift(fullProduct.high_quality_image);
    }
  };

  const getImage = () => {
    if (fullProduct && fullProduct.images && fullProduct.images.length > 0) {
      return fullProduct.images[0];
    }

    return withoutImage;
  };

  const setSelectedImage = useCallback(() => {
    if (order && fullProduct) {
      addHighQualityImage();

      const image = getImage();

      setImageSelected(image ?? withoutImage);
    }
  }, [order?.dst_shop_id, fullProduct.ean]);

  useEffect(() => setSelectedImage(), [setSelectedImage]);

  return (
    <Col md={7} lg={6}>
      <Row>
        <Col md={2} lg={1}>
          <MiniImages
            imageSelected={imageSelected}
            images={fullProduct.images}
            changeImageSelected={changeImageSelected}
          />
        </Col>

        <Col md={10} className="my-auto">
          <BgImage src={imageSelected} styles={{ height: '400px' }} />
        </Col>
      </Row>
      {fullProduct.medicine_strip === MedicineStripe.BLACK && (
        <Row className="p-2">
          <div className="black-stripe-warning">
            <span className="title">ITEM COM RETENÇÃO DE RECEITA</span>

            <span>
              A compra desse item está sujeita à apresentação, avaliação e retenção da{' '}
              <b>receita original</b> pelo farmacêutico. Venda apenas com <b>prescrição médica</b>.
              O abuso deste medicamento pode causar <b>dependência</b>.
              {!configs.allow_delivery_to_black_strip && configs.allow_add_black_strip_to_cart && (
                <p className="m-0 mt-3">
                  Ao adicionar esse produto ao seu carrinho, o seu pedido só poderá ser{' '}
                  <b>retirado em loja</b>.
                </p>
              )}
            </span>
          </div>
        </Row>
      )}
    </Col>
  );
}

export default ImagePreview;
